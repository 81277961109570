<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">推广数</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<div class="d-flex align-items-center m-2">
						<div class="label ">选择月份：</div>
						<el-date-picker v-model="search.date" @change="toSearch" type="month" placeholder="选择月">
						</el-date-picker>
					</div>
					<div class="d-flex align-items-center m-1">
						<div class="label ">单位：</div>
						<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
							<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
								:value="item.unit_id">
							</el-option>
						</el-select>
						<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
							<el-option label="请选择" :key="0" :value="0">
							</el-option>
							<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
								:value="item.unit_id">
							</el-option>
						</el-select>
						<el-select v-model="search.third_id" @change="toSearch()" placeholder="请请选择街道">
							<el-option label="请选择" :key="0" :value="0"></el-option>
							<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
								:value="item.unit_id"></el-option>
						</el-select>
					</div>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
					<el-button class="ml-1" @click="handleExport" type="primary" size="mini">
						导出
					</el-button>
				</div>
			</div>
			<el-table v-if="lists.length > 0" border class="mt-3" :data="lists" style="width: 100%"
				:default-expand-all="true">
				<el-table-column align="center" label="部门">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.unit_name}}</span>
					</template>
				</el-table-column>
				<template v-if="dateArr.length > 0" v-for="(item, index) in dateArr">
					<el-table-column width="48" align="center" :label="item.toString()">
						<template slot-scope="scope">
							<template v-if="search.first_id > 0 && search.second_id == 0">
								<template
									v-if="dateUnitNum[item+'_'+search.first_id+'_'+scope.row.unit_id]">{{dateUnitNum[item+'_'+search.first_id+'_'+scope.row.unit_id]}}</template>
								<template v-else>
									0
								</template>
							</template>
							<template v-else>
								<template
									v-if="dateUnitNum[item+'_'+search.first_id+'_'+search.second_id+'_'+scope.row.unit_id]">{{dateUnitNum[item+'_'+search.first_id+'_'+search.second_id+'_'+scope.row.unit_id]}}</template>
								<template v-else>
									0
								</template>
							</template>
						</template>
					</el-table-column>
				</template>
				<el-table-column align="center" label="总计">
					<template slot-scope="scope">
						<template v-if="search.first_id > 0 && search.second_id == 0">
							<template
								v-if="dateUnitNum['32_'+search.first_id+'_'+scope.row.unit_id]">{{dateUnitNum['32_'+search.first_id+'_'+scope.row.unit_id]}}</template>
							<template v-else>
								0
							</template>
						</template>
						<template v-else>
							<template
								v-if="dateUnitNum['32_'+search.first_id+'_'+search.second_id+'_'+scope.row.unit_id]">{{dateUnitNum['32_'+search.first_id+'_'+search.second_id+'_'+scope.row.unit_id]}}</template>
							<template v-else>
								0
							</template>
						</template>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				dateArr: [],
				unitList: [],
				dateUnitNum: {},
				firstList: [],
				secondList: [],
				thirdList: [],
				search: {
					s: 'store/statistics/index',
					date: '',
					first_id: 0,
					second_id: 0,
					third_id: 0,
				},
			}
		},
		created() {
			this.getUnitList();
		},
		methods: {
			handleExport() {
				this.axios({
					token: true,
					params: {
						s: 'store/Statistics/dateStatisticsExport',
						date: this.search.date,
						first_id: this.search.first_id,
						second_id: this.search.second_id,
						third_id: this.search.third_id,
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'data_export.xls'
					}
				}).catch(err => {})
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
				this.toSearch()
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
				this.toSearch()
			},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data
					this.search.first_id = res.data.data[0].unit_id
					this.secondList = res.data.data[0].child
					this.getList()
				}).catch(err => {});
			},
			toSearch() {
				this.getList()
			},
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					if (res.data.code == 1) {
						this.lists = res.data.data.unitList
						this.dateArr = res.data.data.dateArr
						this.dateUnitNum = res.data.data.dateUnitNum
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
